import React, { useState } from 'react';
import { formatDate } from '../../helpers/formatDateHelper'
import  CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import InspectionFollowUpDeviationModal from '../InspectionFollowUpDeviationModal/InspectionFollowUpDeviationModal';
import InspectionFollowUpAddDeviationInspectionTypeModal from '../InspectionFollowUpAddDeviationInspectionTypeModal/InspectionFollowUpAddDeviationInspectionTypeModal';
import "./InspectionFollowUpView.css"
import ConfirmationButton from '../ConfirmationButton/ConfirmationButton';

const InspectionFollowUpView = ( { followUpChecksToShow, handleAddItemsToFollowUp } ) => {
  const [isFollowUpDeviationModalOpen, setIsFollowUpDeviationModalOpen] = useState(false);
  const [isAddDeviationInspectionTypeModalOpen, setIsAddDeviationInspectionTypeModalOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const handleOpenFollowUpDeviationModal = (itemId) => {
    const selectedItem = followUpChecksToShow.find(f => f.itemId === itemId);
    setItemSelected(selectedItem);
    setIsFollowUpDeviationModalOpen(true);
  }
  const handleCloseFollowUpDeviationModal = () => {
    setIsFollowUpDeviationModalOpen(false);
  }
  const handleOpenAddDeviationInspectionTypeModal = () => {
    setIsAddDeviationInspectionTypeModalOpen(true);
  }
  const handleCloseAddDeviationInspectionTypeModal = () => {
    setIsAddDeviationInspectionTypeModalOpen(false);
  }
  const handleCheckFollowUpItem = (item) => {
    item.checked = !item.checked;
    setRefresh(!refresh)
  }
  return (
    <div className='inspection-followup-view'>
      <div className='inspection-followup-table'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Skoðunaratriði</b></TableCell>
              <TableCell><b>Dómur</b></TableCell>
              <TableCell><b>Tegund</b></TableCell>
              <TableCell><b>Dagsetning</b></TableCell>
              <TableCell className='inspection-followup-all'><b>Lagað</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              followUpChecksToShow.map(followup => {
                return (
                <TableRow key={followup.itemId}>
                  <TableCell onClick={() => handleOpenFollowUpDeviationModal(followup.itemId)}>{followup.itemName}</TableCell>
                  <TableCell onClick={() => handleOpenFollowUpDeviationModal(followup.itemId)}>{followup.judgementIsName}</TableCell>
                  <TableCell onClick={() => handleOpenFollowUpDeviationModal(followup.itemId)}>{followup.inspectionTypeIsName}</TableCell>
                  <TableCell>{formatDate(followup.deadlineToResolve)}</TableCell>
                  <TableCell className='inspection-followup-icon-cell' onClick={() => handleCheckFollowUpItem(followup)}><CheckCircleIcon style={{color: followup.checked ? 'green' : 'red'}}/></TableCell>
                </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </div>
      <div className='inspection-followup-add-deviation-button'>
        <ConfirmationButton 
          handleSubmit={handleOpenAddDeviationInspectionTypeModal}
          title={"Bæta við skoðunaratriði"} 
        />
      </div>
      {itemSelected &&
      <InspectionFollowUpDeviationModal
        open={isFollowUpDeviationModalOpen}
        handleClose={handleCloseFollowUpDeviationModal}
        item={itemSelected}
        followUpChecksToShow={followUpChecksToShow}
        setItemSelected={setItemSelected}
      />
      }
      <InspectionFollowUpAddDeviationInspectionTypeModal 
        open={isAddDeviationInspectionTypeModalOpen}
        handleClose={handleCloseAddDeviationInspectionTypeModal}
        handleAddItemsToFollowUp={handleAddItemsToFollowUp}
        followUpChecksToShow={followUpChecksToShow}
      />
    </div>
  )
}

export default InspectionFollowUpView;