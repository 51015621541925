import { useState } from 'react';
import loginService from '../services/loginService';
import { useDispatch } from 'react-redux';
import { setLogoutUser } from "../redux/actions/userActions";

const useLogOut = (fn) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const logOut = async (userSSN) => {
        return await loginService.logOut(userSSN)
            .then((user) => { 
                setError(null); 
                dispatch(setLogoutUser(user));
                fn();
            })
            .catch(error => setError(error))
            .finally(() => {});
    }
    return { error, logOut };
}
export default useLogOut;